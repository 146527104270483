import { NextPageWithLayout } from '~pages/_app'
import useTranslation from 'next-translate/useTranslation'
import { Box, Button, Heading, Img, Show, Text, useBreakpointValue, useStyleConfig } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { routes } from '~components/sielbleu/utils/routes'
import ScheduleIcon from '~ui/icons/ScheduleIcon'
import WorkoutIcon from '~ui/icons/WorkoutIcon'
import CallCoachIcon from '~ui/icons/CallCoachIcon'
import TransText from 'next-translate/TransText'
import React, { ReactElement } from 'react'
import Layout from '~components/sielbleu/layout'
import HeadLinks from '~components/sielbleu/head/links'
import Head from 'next/head'

const Page: NextPageWithLayout = () => {
    const { t, lang } = useTranslation('sielbleu')
    const router = useRouter()
    const { query } = router
    const styles = useStyleConfig('Landing', { variant: 'atWork' })

    const imgPath = '/images/sielbleu/'
    const devicesImage = useBreakpointValue({
        base: imgPath + 'landing/devices_webmobile.png',
        md: imgPath + 'landing/devices_web.png',
    })

    // handle redirection on localhost

    const handleRegisterRedirection = () => {
        const port = window.location.port ? ':' + window.location.port : ''

        window.location = ('//' +
            process.env.NEXT_PUBLIC_SIELBLEU_DOMAIN +
            port +
            '/' +
            lang +
            routes.public.register +
            '?' +
            new URLSearchParams({
                ...query,
                type: 'WORK',
            }).toString()) as any
    }

    return (
        <>
            <HeadLinks />
            <Head>
                <title>
                    {t('sielbleu.landing.work.title')} -{t('sielbleu.landing.adapted-physical-activity')}{' '}
                    {t('sielbleu.landing.work.at-work')}
                </title>
                <meta name="description" content={t('sielbleu.landing.work.description.text')} />
                <meta property="og:title" content={t('sielbleu.landing.work.title')} />
                <meta property="og:site_name" content="Siel Bleu at work" />
                <meta property="og:url" content={process.env.NEXT_PUBLIC_LANDING_WORK_URL} />
                <meta property="og:description" content={t('sielbleu.landing.work.description.text')} />
                <meta property="og:type" content="website" />
                <meta
                    property="og:image"
                    content={
                        process.env.NEXT_PUBLIC_LANDING_WORK_URL + '/images/sielbleu/SielBleuAtWork/header_image.jpg'
                    }
                />
            </Head>
            <Box __css={styles}>
                <Box className="header-banner">
                    <Heading className="main-title">{t('sielbleu.landing.work.title')}</Heading>
                    <Heading className="motivation-title">{t('sielbleu.landing.work.motivation')}</Heading>
                    <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                </Box>
                <Box className="container">
                    <Box className="description">
                        <Box className="description-text">
                            <Heading className="section-title">{t('sielbleu.landing.work.description.title')}</Heading>
                            <Text className="section">{t('sielbleu.landing.work.description.text')}</Text>
                        </Box>
                        <Img src={devicesImage} alt="devices" />
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.work.details.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-blue">
                                <ScheduleIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.work.details.card-1.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.work.details.card-1.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <WorkoutIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.work.details.card-2.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.work.details.card-2.text')}</Text>
                            </Box>
                            <Box className="card-blue">
                                <CallCoachIcon boxSize={105} />
                                <Heading variant="tile">{t('sielbleu.landing.work.details.card-3.title')}</Heading>
                                <Text className="section">{t('sielbleu.landing.work.details.card-3.text')}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Heading className="section-title">{t('sielbleu.landing.work.pro.title')}</Heading>
                        <Box className="card-container">
                            <Box className="card-white">
                                <Box className="card-white-title one">
                                    <Heading>{t('sielbleu.landing.work.card-1.title')}</Heading>
                                </Box>
                                <Text className="section">{t('sielbleu.landing.work.pro.card-1.text')}</Text>
                            </Box>
                            <Box className="card-white">
                                <Box className="card-white-title two">
                                    <Heading>{t('sielbleu.landing.work.pro.card-2.title')}</Heading>
                                </Box>
                                <Text className="section">{t('sielbleu.landing.work.pro.card-2.text')}</Text>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="card-full-blue">
                        <Heading>{t('sielbleu.landing.work.customized')}</Heading>
                        <Text>{t('sielbleu.landing.work.text')}</Text>
                        <Button onClick={handleRegisterRedirection}>{t('sielbleu.landing.signup')}</Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

const PageLanding = Page

PageLanding.getLayout = function getLayout(page: ReactElement) {
    return <Layout>{page}</Layout>
}

export default PageLanding
